import React, { useRef } from "react";
import styled from "styled-components";
import SplitLines from "./anims/SplitLines";

const Title = ({ h, className, children }) => {
  const ref = useRef(null);
  const H = h || "h2";
  const classN = `h ${className}` || "h";

  return (
    <StyledTitle ref={ref}>
      <H className={classN}>
        <span>{children}</span>
      </H>
    </StyledTitle>
  );
};

export default Title;

const StyledTitle = styled.div`
  .h {
  }
`;
