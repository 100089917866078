import React, {
  useRef,
  useEffect,
  useState,
  useLayoutEffect,
  useContext,
} from "react";
import { colours } from "../../vars/colours";
import styled from "styled-components";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { circ, cubic, quint } from "../../vars/ease";
import { GlobalStateContext } from "../../context/Global";

const Progress = () => {
  const { preloaded, transitioned } = useContext(GlobalStateContext);
  const Bar = useRef(null);
  const [end, setEnd] = useState(false);
  const footer = useRef(null);
  let ctx = gsap.context(() => {});
  const pin = useRef(null);
  gsap.registerPlugin(ScrollTrigger);

  setTimeout(() => {
    setEnd(true);
  }, 4000);

  useEffect(() => {
    function isTouchDevice() {
      return (
        "ontouchstart" in window ||
        navigator.maxTouchPoints > 0 ||
        navigator.msMaxTouchPoints > 0
      );
    }

    // ScrollTrigger.addEventListener("refreshInit", () => setEnd(true));

    const setProgress = () => {
      ctx.add(() => {
        const tl = gsap.timeline({ paused: true });
        const footer = document.querySelector("footer");
        let endTrigger = document.body.scrollHeight - footer.offsetHeight * 3;

        gsap.matchMedia().add("(min-width: 1200px)", () => {
          endTrigger = document.body.scrollHeight - footer.offsetHeight * 3;
        });

        tl.to(Bar.current, {
          scaleX: 1,
          ease: "none",
        });

        gsap.fromTo(
          Bar.current,
          {
            y: 10,
          },
          {
            y: 0,
            duration: 0.8,
            ease: cubic,
          }
        );

        pin.current = ScrollTrigger.create({
          start: "+=120",
          scrub: true,
          end: "top 100%",
          endTrigger: "footer",
          animation: tl,
          onLeave: () => {
            gsap.to(Bar.current, {
              autoAlpha: 0,
              duration: 0.01,
              ease: "none",
            });
          },

          onEnterBack: () => {
            gsap.to(Bar.current, {
              autoAlpha: 1,
              duration: 0.01,
              ease: "none",
            });
          },
        });
      });
    };

    if (end) {
      setProgress();
    }

    const revert = () => {
      // pin.current && pin.current.revert();

      setTimeout(() => {
        pin.current && pin.current.refresh();
      }, 430);
    };
    window.addEventListener("resize", revert);
  }, [end]);

  return <ProgressBar ref={Bar}></ProgressBar>;
};

export default Progress;

const ProgressBar = styled.div`
  will-change: transform;
  transform-origin: left;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  transform: scaleX(0);
  pointer-events: none;
  background-color: ${colours.accent};
  height: 0.25rem;
  z-index: 2;
`;
