import React, { useContext } from "react";
import styled from "styled-components";
import { useEffect, useLayoutEffect } from "react";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import { SplitText } from "gsap/SplitText";
import { useRef } from "react";
import { cubic, quint, quintOut } from "../../vars/ease";

const SplitLines = ({ children }) => {
  const ref = useRef(null);
  const textAnim = useRef(null);
  const splitChild = useRef(null);
  const splitParent = useRef(null);
  const ctx = useRef(null);

  function isTouchDevice() {
    return (
      "ontouchstart" in window ||
      navigator.maxTouchPoints > 0 ||
      navigator.msMaxTouchPoints > 0
      // false
    );
  }

  useLayoutEffect(() => {
    gsap.registerPlugin(SplitText, ScrollTrigger);
    const dm = gsap.matchMedia();
    const mm = gsap.matchMedia();

    const setText = () => {
      ctx.current = gsap.context(() => {
        const tag = ref.current.querySelector("p, span");

        const text = tag;

        text.innerHTML = cleanText(text.innerHTML);

        function cleanText(text) {
          return text.split("\n").join("</p>");
        }

        splitChild.current = new SplitText(tag, {
          type: "lines",
          linesClass: "lc",
        });

        splitParent.current = new SplitText(tag, {
          type: "lines",
          linesClass: "lp",
        });

        textAnim.current = gsap.timeline({
          scrollTrigger: {
            trigger: ref.current,
            start: "top bottom -60px",
          },
        });

        dm.add("(min-width: 1200px)", () => {
          gsap.set(ref.current.querySelectorAll(".lc"), {
            y: "20vw",
            rotate: 10,
            z: 0.01,
          });

          ScrollTrigger.create({
            trigger: ref.current,
            start: "top bottom -60px",
            invalidateOnRefresh: true,
            onEnter: () => {
              gsap.to(ref.current.querySelectorAll(".lc"), {
                y: 0,
                rotate: 0,
                z: 0,
                duration: 0.7,
                stagger: 0.15,
                ease: quintOut,
              });
            },
          });
        });

        // mm.add("(max-width: 1199px)", () => {
        //   gsap.set(ref.current.querySelectorAll(".lc"), {
        //     yPercent: 250,
        //     rotate: 5,
        //     z: 0.01,
        //   });

        //   ScrollTrigger.create({
        //     trigger: ref.current,
        //     start: "top bottom -60px",
        //     invalidateOnRefresh: true,
        //     onEnter: () => {
        //       gsap.to(ref.current.querySelectorAll(".lc"), {
        //         yPercent: 0,
        //         rotate: 0,
        //         duration: 1,
        //         stagger: 0.15,
        //         z: 0,
        //         ease: quintOut,
        //       });
        //     },
        //   });
        // });
      });
    };

    !isTouchDevice()
      ? setTimeout(() => {
          setText();
        }, 300)
      : setText();

    const revert = () => {
      splitParent.current && splitParent.current.revert();
      splitChild.current && splitChild.current.revert();
      // ctx.current && ctx.current.revert();
    };

    window.addEventListener("resize", revert);

    return () => {
      window.removeEventListener("resize", revert);
      ctx.current && ctx.current.revert();
      dm.revert();
      mm.revert();
    };
  }, [ref]);

  return <StyledText ref={ref}>{children}</StyledText>;
};

export default SplitLines;

const StyledText = styled.div`
  will-change: transform;

  p {
    white-space: pre-line;

    .lc {
      will-change: transform;
      letter-spacing: -0.02rem;
    }
  }
`;
