import React, { useState, useEffect, useContext, useRef } from "react";
import styled from "styled-components";
import gsap from "gsap";
import { graphql, Link, useStaticQuery } from "gatsby";
import { FiChevronUp } from "react-icons/fi";
import { device } from "../../vars/media";
import { IoMdClose } from "react-icons/io";
import { GlobalStateContext } from "../../context/Global";
import { colours } from "../../vars/colours";

const Nav = (props) => {
  const pageTitle = useRef(document.location.pathname);
  const cleanTitle = useRef(null);
  const { portfolioEnter } = useContext(GlobalStateContext);
  const data = useStaticQuery(query);
  const links = data.allSanityNavigation.edges[0].node.items;
  const [inPort, setInPort] = useState(
    pageTitle.current.includes("portfolio") &&
      pageTitle.current !== "/portfolio/"
  );

  useEffect(() => {
    if (pageTitle.current === "/") {
      pageTitle.current = "Home";
    } else if (inPort) {
      pageTitle.current = "Close";
    } else {
      pageTitle.current = document.location.pathname;
    }
    cleanTitle.current = pageTitle.current.replace(/[^a-zA-Z ]/g, "");
  }, [inPort]);

  const [toggleNav, setToggleNav] = React.useState(false);
  const mainNav = React.useRef(null);
  const bottomNav = React.useRef(null);
  const tl = React.useRef();

  useEffect(() => {
    tl.current = gsap.timeline({ paused: true });
    const navIcon = bottomNav.current.querySelector("svg");
    const currentLabel = bottomNav.current.children[1];
    const closeLabel = bottomNav.current.children[2];
    const menuItems = mainNav.current.querySelectorAll(".nav__item");
    const navAnim = gsap.timeline({ paused: true });

    tl.current
      .to(navIcon, { rotate: 180 }, 0.5)
      .to(
        bottomNav.current,
        { paddingRight: "calc(40px + 118px)", ease: "circ", duration: 1.1 },
        0
      )
      .to(
        currentLabel,
        { duration: 0.1, opacity: 0, width: 0, ease: "circ", duration: 0.6 },
        0
      )
      .to(closeLabel, { opacity: 1, y: 0, ease: "cubic", duration: 0.6 }, 0.6)
      .to(
        menuItems,
        {
          duration: 0.5,
          opacity: 1,
          y: 0,
          stagger: 0.1,
          ease: "cubic",
          duration: 0.6,
        },
        0.5
      )
      .to(
        mainNav.current,
        {
          duration: 2,
          maxHeight: "300px",
          padding: "1.5625rem",
          ease: "circ",
          duration: 0.6,
        },
        0.3
      )
      .reversed();
  }, []);

  React.useEffect(() => {
    toggleNav ? tl.current.play() : tl.current.reverse();
  }, [toggleNav]);

  const navItems = links.map((link, index) => {
    const item = link.navigationItemUrl;
    let url = "";
    const title = link.text;

    if (item.internalLink && item.internalLink.slug_regular_custom_input) {
      url = link.navigationItemUrl.internalLink.slug_regular_custom_input;
    }

    return item.externalUrl !== "#contact" ? (
      <Link
        to={`/${url.current == "home" ? "" : url.current}`}
        className="nav__item"
        activeClassName="nav__item-active"
      >
        <div className="dot">
          <div className="dot__inner"></div>
        </div>
        {title}
      </Link>
    ) : (
      <span className="nav__item nav__item-contact-m">
        <div className="dot">
          <div className="dot__inner"></div>
        </div>
        {title}
      </span>
    );
  });

  const navInner = (
    <StyledNav className="nav" open={props.open} inPort={inPort}>
      <div className="nav__main" ref={mainNav}>
        {navItems}
      </div>

      <div
        onClick={() => !inPort && setToggleNav(!toggleNav)}
        className="nav__bottom"
        ref={bottomNav}
      >
        <div className="nav__icon">
          {!inPort ? <FiChevronUp /> : <IoMdClose />}
        </div>

        {!inPort ? (
          <span className="current-page">{cleanTitle.current}</span>
        ) : (
          <span className="current-page">{cleanTitle.current}</span>
        )}

        {!inPort && <span className="close-label">Close Navigation</span>}
      </div>
    </StyledNav>
  );

  return (
    <>{!inPort ? navInner : <Link to={portfolioEnter}>{navInner}</Link>}</>
  );
};

export default Nav;

const StyledNav = styled.nav`
  position: fixed;
  left: 50%;
  transform: translate(-50%, 0%);
  bottom: 1.875rem;
  z-index: 5;
  font-weight: 500;
  cursor: pointer;
  opacity: 0;
  text-decoration: none;
  color: ${colours.black};

  ${device.laptop} {
    display: none;
  }

  a {
    all: unset;
  }

  .close-label {
    position: absolute;
    left: 70px;
    opacity: 0;
  }

  .current-page {
    text-transform: capitalize;
  }

  .nav__main {
    background-color: white;
    /* padding: 1.5625rem; */
    border-radius: 30px;
    transform-origin: bottom;
    max-height: 0px;
    overflow: hidden;
    -webkit-box-shadow: 0px 4px 30px -10px rgba(0, 0, 0, 0.25);
    box-shadow: 0px 4px 30px -10px rgba(0, 0, 0, 0.25);

    .nav__item {
      display: flex;
      align-items: center;
      margin: 30px 0;
      position: relative;
      opacity: 0;
      transform: translateY(5px);
      /* 
      &:last-of-type {
        margin-bottom: 0;

        &:before {
          display: none;
        }
      } */

      &:first-of-type:not(span) {
        margin-top: 0;
      }

      &.nav__item-contact-m {
        margin-bottom: 0;
        &:before {
          display: none;
        }
      }

      .dot {
        display: block;
        margin-right: 20px;
        z-index: 1;
        padding: 5px;
        border-radius: 20px;
        margin-left: -6px;
        border: 1px solid transparent;

        &__inner {
          background: #d9d9d9;
          height: 9px;
          width: 9px;
          border-radius: 20px;
        }
      }

      &-active {
        .dot {
          background-color: white;
          border: 1px solid #ff4800;

          &__inner {
            background: #ff4800;
          }
        }
      }

      &::before {
        content: "";
        display: block;
        position: absolute;
        left: 4px;
        top: 9px;
        width: 1px;
        height: calc(100% + 30px);
        z-index: 0;
        background-color: #d9d9d9;
        opacity: 0.5;
      }
    }
  }

  .nav__bottom {
    background-color: white;
    display: inline-flex;
    align-items: center;
    padding: 10px;
    border-radius: 4.375rem;
    padding-right: 40px;
    margin-top: 1rem;
    white-space: nowrap;
    -webkit-box-shadow: 0px 4px 30px -10px rgba(0, 0, 0, 0.25);
    box-shadow: 0px 4px 30px -10px rgba(0, 0, 0, 0.25);
  }

  .nav__icon {
    height: 2.5rem;
    width: 2.5rem;
    border-radius: 2.5rem;
    border: 1px solid #d9d9d9;
    margin-right: 1.25rem;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      transform-origin: center;
    }
  }
`;

export const query = graphql`
  query Nav {
    allSanityNavigation(filter: { title: { eq: "Main Menu" } }) {
      edges {
        node {
          id
          items {
            text
            navigationItemUrl {
              internalLink {
                slug_regular_custom_input {
                  current
                }
              }
              externalUrl
            }
          }
        }
      }
    }
  }
`;
