import React, { useState, useContext, useRef, useEffect } from "react";
import { TransitionGroup, Transition } from "react-transition-group";
import { gsap } from "gsap";
import ScrollSmoother from "gsap/ScrollSmoother";
import ScrollTrigger from "gsap/ScrollTrigger";
import styled from "styled-components";
import { quart, quint, cubic, circ } from "../vars/ease";
import { GlobalStateContext } from "../context/Global";
import Mark from "../images/mark.svg";
import { colours } from "../vars/colours";
import { device } from "../vars/media";

const PageTransition = ({ children, location }) => {
  gsap.registerPlugin(ScrollSmoother, ScrollTrigger);

  const {
    transitioned,
    setTransitioned,
    setCursorIn,
    setPortfolioEnter,
    setInPortfolio,
    inPortfolio,
    setScrollP,
    scrollP,
  } = useContext(GlobalStateContext);

  const wipeFirst = useRef(null);
  const wipeSecond = useRef(null);
  const wipeGroup = useRef(null);
  const inTl = useRef(null);
  const outTl = useRef(null);
  const portTl = useRef(null);
  const enterP = useRef(null);
  const exitP = useRef(null);

  useEffect(() => {
    inTl.current = gsap.timeline({
      paused: true,
      onStart: () => {
        gsap.set(wipeGroup, {
          zIndex: 99,
        });
      },
      onComplete: () => {
        gsap.set(wipeFirst.current.querySelector(".wipe__inner"), {
          clearProps: "all",
        });

        gsap.set(wipeGroup, {
          clearProps: "all",
        });
      },
    });
    inTl.current
      .to(
        wipeFirst.current,
        {
          y: 0,
          ease: circ,
          duration: 0.4,
        },
        0
      )
      .to(
        wipeFirst.current.querySelector(".wipe__inner"),
        {
          y: 0,
          ease: circ,
          duration: 0.4,
        },
        0
      )
      .to(
        wipeGroup.current.querySelectorAll("svg"),
        {
          scale: 1.05,
          ease: circ,
          duration: 0.3,
        },
        0.1
      )

      .to(
        wipeGroup.current.querySelectorAll("svg"),
        {
          scale: 1.1,
          ease: circ,
          duration: 0.3,
        },
        0.4
      )

      .to(
        wipeSecond.current,
        {
          y: 0,
          ease: circ,
          duration: 0.4,
        },
        0.4
      )

      .to(
        wipeSecond.current.querySelector(".wipe__inner"),
        {
          y: 0,
          ease: circ,
          duration: 0.4,
        },
        0.4
      );

    // Out Tl

    outTl.current = gsap.timeline({
      paused: true,
      onStart: () => {
        setTimeout(() => {
          setTransitioned(true);
        }, 20);
      },
      onComplete: () => {
        gsap.set(wipeSecond.current, {
          clearProps: "all",
        });
        gsap.set(wipeSecond.current.querySelector(".wipe__inner"), {
          clearProps: "all",
        });

        gsap.set(wipeGroup.current.querySelectorAll("svg"), {
          clearProps: "all",
        });

        gsap.set(wipeFirst.current, {
          clearProps: "all",
        });
      },
    });

    outTl.current
      .to(
        wipeSecond.current,
        {
          yPercent: -100,
          ease: circ,
          duration: 0.55,
        },
        0.2
      )
      .to(
        wipeSecond.current.querySelector(".wipe__inner"),
        {
          yPercent: 100,
          ease: circ,
          duration: 0.55,
        },
        0.2
      );
  }, []);

  return (
    <TransitionGroup>
      <div
        ref={wipeGroup}
        className="wipe-group"
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          zIndex: 99,
          pointerEvents: "none",
        }}
      >
        <Wipe ref={wipeFirst} className="wipe-first">
          <div className="wipe__inner">
            <Mark />
          </div>
        </Wipe>

        <Wipe ref={wipeSecond} className="accent wipe">
          <div className="wipe__inner">
            <Mark />
          </div>
        </Wipe>
      </div>

      <Transition
        key={location.pathname}
        timeout={1100}
        in={true}
        unmountOnExit={true}
        onEnter={(node) => {
          enterP.current = location.pathname;

          if (
            enterP.current.includes("portfolio") &&
            enterP.current !== "/portfolio/"
          ) {
            gsap.to(node, {
              yPercent: 0,
              z: 0.01,
              willChange: "transform",
              duration: 1,
              ease: quint,
            });

            gsap.set(node.querySelector(".inset"), { overflow: "visible" });
          } else if (inPortfolio) {
          } else {
            gsap.set(node, { opacity: 0 });
          }
        }}
        onExit={(node) => {
          function isTouchDevice() {
            return (
              "ontouchstart" in window ||
              navigator.maxTouchPoints > 0 ||
              navigator.msMaxTouchPoints > 0
              // false
            );
          }

          const smoother = ScrollSmoother.get();
          const currentScroll = smoother
            ? smoother.scrollTop()
            : window.scrollY;

          if (!isTouchDevice()) {
            // const smoother = ScrollSmoother.get();
            // const currentScroll = smoother.scrollTop();
            smoother && smoother.paused(true);
            exitP.current = location.pathname;

            setTimeout(() => {
              smoother && smoother.kill();
            }, 10);
          }

          if (
            enterP.current.includes("portfolio") &&
            enterP.current !== "/portfolio/"
          ) {
            setScrollP(currentScroll);
            setPortfolioEnter(location.pathname);
            setInPortfolio(true);
            gsap.to(".ol", {
              autoAlpha: 1,
              duration: 0.7,
              ease: quint,
            });
            gsap.set("main:nth-of-type(1)", {
              width: "100%",
              position: "fixed",
              x: 0,
              yPercent: 100,
              zIndex: 10,
            });
          } else if (inPortfolio) {
            setPortfolioEnter(location.pathname);
            setInPortfolio(true);

            gsap.set("main:nth-of-type(2)", {
              width: "100%",
              position: "absolute",
              zIndex: 1,
            });

            gsap.set("main:nth-of-type(1)", {
              width: "100%",
              position: "absolute",
              zIndex: 10,
              autoAlpha: 0,
            });

            gsap.to("main", {
              autoAlpha: 0,
              duration: 1,
              ease: quint,
              onComplete: () => {
                setTransitioned(false);
              },
            });
          } else {
            gsap.set("main:nth-of-type(1)", {
              position: isTouchDevice() ? "fixed" : "absolute",
              width: "100%",
              // display: "none",
              height: 0,
            });
            inTl.current.play(0);
          }
        }}
        onEntered={(node) => {
          !enterP.current.includes("portfolio") &&
            enterP.current !== "/portfolio/" &&
            setInPortfolio(false);

          function isTouchDevice() {
            return (
              "ontouchstart" in window ||
              navigator.maxTouchPoints > 0 ||
              navigator.msMaxTouchPoints > 0

              // false
            );
          }

          enterP.current == "/portfolio/" && setInPortfolio(false);

          if (
            enterP.current.includes("portfolio") &&
            enterP.current !== "/portfolio/"
          ) {
            // setTimeout(() => {
            //   ScrollTrigger.refresh();
            // }, 100);
            setTimeout(() => {
              // ScrollTrigger.refresh();
              gsap.set(node, { clearProps: "all" });
              gsap.set("main", { clearProps: "all" });
              gsap.set(".ol", { clearProps: "all" });
              ScrollTrigger.refresh();
            }, 500);
          } else if (inPortfolio) {
            // setTransitioned(false);

            // gsap.set(node, { clearProps: "all" });

            setTransitioned(true);

            setTimeout(() => {
              ScrollTrigger.refresh();
            }, 20);

            // gsap.set("main", { clearProps: "all" });
            gsap.set("main .current", { clearProps: "all" });
            gsap.set(".ol", { clearProps: "all" });

            gsap.to(node, {
              autoAlpha: 1,
              duration: 1,
              delay: 0.4,
              onStart: () => {
                if (isTouchDevice()) {
                  window.scrollTo(0, scrollP);
                }
              },
              onComplete: () => {
                gsap.set(node, {
                  clearProps: "all",
                });
              },
            });

            // setTimeout(() => {
            // }, 300);

            // gsap.to(node, {
            //   autoAlpha: 1,
            //   duration: 2,
            //   ease: quint,
            //   onStart: () => {
            // if (isTouchDevice()) {
            //   window.scrollTo(0, scrollP);
            // }
            //     // setTimeout(() => {
            //     //   setTransitioned(true);
            //     // }, 20);
            //   },
            //   onComplete: () => {},
            // });
          } else {
            gsap.set(node, { opacity: 1 });

            setTimeout(() => {
              gsap.set(node, { clearProps: "all" });
            }, 300);

            setTransitioned(false);
            gsap.set(wipeFirst.current, { autoAlpha: 0 });
            outTl.current.play(0);
          }
        }}
      >
        {children}
      </Transition>
    </TransitionGroup>
  );
};

export default PageTransition;

const Wipe = styled.div`
  will-change: transform;
  background: #000;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  transform: translateY(100%);
  transform-origin: bottom;
  height: 100vh;

  overflow: hidden;

  .wipeGroup {
    position: fixed;
    z-index: -1;
  }

  .wipe__inner {
    will-change: transform;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: translateY(-100%);

    svg {
      ${device.laptopM} {
        width: 206px;
        height: auto;
      }
    }
  }

  svg {
    will-change: transform;
    path {
      fill: #fff;
    }
  }

  &.accent {
    background-color: ${colours.accent};

    svg path {
      fill: ${colours.black};
    }
  }
`;
