import React, { createContext, useState } from "react";

export const GlobalStateContext = createContext(
  false,
  false,
  false,
  false,
  false,
  false
);

export const GlobalStateProvider = ({ children }) => {
  const [transitioned, setTransitioned] = useState(false);
  const [portfolioEnter, setPortfolioEnter] = useState("/portfolio");
  const [cursorIn, setCursorIn] = useState(false);
  const [preloaded, setPreloaded] = useState(false);
  const [inPortfolio, setInPortfolio] = useState(false);
  const [scrollP, setScrollP] = useState(false);

  return (
    <GlobalStateContext.Provider
      value={{
        preloaded,
        setPreloaded,
        transitioned,
        setTransitioned,
        cursorIn,
        setCursorIn,
        portfolioEnter,
        setPortfolioEnter,
        inPortfolio,
        setInPortfolio,
        scrollP,
        setScrollP,
      }}
    >
      {children}
    </GlobalStateContext.Provider>
  );
};
