import React from "react";
import styled from "styled-components";
import Wrapper from "../Wrapper";
import { Link } from "gatsby";
import Logo from "../../images/svg/bertchcapital-logo.svg";
import { device } from "../../vars/media";

const Header = ({ theme }) => {
  return (
    <StyledHeader theme={theme}>
      <Wrapper>
        <Link to="/">
          <Logo />
        </Link>
      </Wrapper>
    </StyledHeader>
  );
};

export default Header;

const StyledHeader = styled.header`
  display: flex;
  width: 100%;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 5;

  .wrapper {
    margin: 1.875rem;
  }

  svg {
    path {
      fill: ${(props) => (props.theme == "dark" ? "#000" : "#fff")};
    }
  }

  .dark {
    path {
      fill: #000;
    }
  }

  /* ${device.desktop} {
    svg {
      width: 9.875vw;
      height: auto;
    }
  } */
`;
