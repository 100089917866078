import React from "react";
import styled from "styled-components";
import { colours } from "../../vars/colours";
import { device } from "../../vars/media";

const Icon = ({ children, height }) => {
  return (
    <StyledIcon height={height} className="icon">
      {children}
    </StyledIcon>
  );
};

export default Icon;

const StyledIcon = styled.div`
  height: 2.5rem;
  width: 2.5rem;
  border-radius: 2.5rem;
  border: 1px solid ${colours.border};
  margin-right: 0.9rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  svg {
    max-height: ${(props) => (props.height ? `${props.height}px` : "unset")};
    transform-origin: center;
    fill: ${colours.accent};
    stroke-width: 0.2;
    stroke: ${colours.accent};
    margin-right: 0.7rem;
  }
`;
