import React, { useEffect, useRef, useContext } from "react";
import styled from "styled-components";
import gsap from "gsap";
import { GlobalStateContext } from "../../context/Global";
import { StaticImage } from "gatsby-plugin-image";
import { circ, cubic } from "../../vars/ease";
import Wrapper from "../Wrapper";
import Logo from "../../images/svg/bertchcapital-logo.svg";
import Mark from "../../images/mark.svg";
import { colours } from "../../vars/colours";
import { device } from "../../vars/media";
import ScrollTrigger from "gsap/ScrollTrigger";

const Preloader = () => {
  gsap.registerPlugin(ScrollTrigger);
  const { preloaded, setPreloaded, setTransitioned } =
    useContext(GlobalStateContext);

  const swipe = useRef(null);
  const tl = useRef(null);
  const pre = useRef(null);

  useEffect(() => {
    if (!preloaded) {
      setTransitioned(true);

      gsap.set("body", { opacity: 1 });

      tl.current = gsap.timeline({
        paused: true,
        onComplete: () => {
          setPreloaded(true);
        },
      });
      let finalHeight = "100vh";
      let finalY = "0";
      const imgWrap = pre.current.querySelector(".img-wrap");
      const imgOuter = pre.current.querySelector(".img-outer");
      const img = pre.current.querySelector(".gatsby-image-wrapper");
      const logoWrap = pre.current.querySelector(".logos");
      const logoOne = pre.current.querySelectorAll("svg:nth-of-type(1) path");
      const logoTwo = pre.current.querySelectorAll("svg:nth-of-type(2) path");

      gsap.matchMedia().add("(min-width: 1200px)", () => {
        finalHeight = "120vh";
        finalY = "10vh";
      });

      tl.current
        .to(
          imgOuter,
          {
            y: 0,
            duration: 0.55,
          },
          0
        )
        .to(
          imgWrap,
          {
            y: 0,
            duration: 0.9,
            ease: circ,
          },
          0
        )
        .to(
          logoWrap,
          {
            autoAlpha: 1,
            duration: 0.9,
          },
          0.2
        )
        .to(
          img,
          {
            y: 0,
            duration: 0.9,
            ease: circ,
            onComplete: () =>
              gsap.set(img, {
                yPercent: -50,
                xPercent: -50,
                left: "50%",
                top: "50%",
              }),
          },
          0
        )
        .to(
          swipe.current,
          {
            scaleY: 1,
            duration: 0.95,
            ease: circ,
          },
          0.65
        )
        .to(
          imgOuter,
          {
            "-webkit-filter": "grayscale(0)",
            filter: "grayscale(0)",
            duration: 0.65,
            ease: circ,
          },
          0.65
        )

        .to(
          logoTwo,
          {
            fill: "#fff",
            duration: 0.15,
            ease: circ,
          },
          0.9
        )

        .to(
          logoOne,
          {
            fill: "#fff",
            duration: 0.15,
            ease: circ,
          },
          1.27
        )

        .to(
          img,
          {
            width: "100vw",
            height: finalHeight, //1200 = 120vh
            y: finalY, //1200 = 10vh
            duration: 0.82,
            ease: cubic,
          },
          1.3
        )

        .to(
          logoTwo,
          {
            autoAlpha: 0,
            duration: 0.03,
            ease: cubic,
          },
          1.8843
        )

        .to(
          imgWrap,
          {
            maxWidth: "unset",
            maxHeight: "unset",
            width: "100vw",
            height: "100vh",
            duration: 1.2,
            ease: circ,
            onComplete: () => {
              gsap.set(pre.current, { autoAlpha: 0 });
            },
          },
          1.3
        );

      tl.current.timeScale(0.92);
      tl.current.play(0);
    }
  }, []);

  return (
    !preloaded && (
      <Preload ref={pre}>
        <div className="img-outer">
          <div className="img-wrap">
            <StaticImage src="../../images/hero.jpg" />
          </div>
        </div>

        <div className="swipe" ref={swipe}></div>

        <div className="logos">
          <Wrapper>
            <Logo />
            <Mark />
          </Wrapper>
        </div>
      </Preload>
    )
  );
};

export default Preloader;

const Preload = styled.div`
  pointer-events: none;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  overflow: visible;
  pointer-events: none;
  z-index: 11;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  will-change: transform filter;

  .logos {
    opacity: 0;
    position: absolute;
    z-index: 0;
    left: 0;
    top: 0;
    width: 100%;
    height: 100vh;
    z-index: 2;

    svg path {
      fill: ${colours.accent};
    }

    svg:nth-of-type(2) {
      width: 40px;
    }

    .wrapper {
      margin: 1.875rem;
      height: 100%;
      justify-content: space-between;
      align-items: center;
      display: flex;
      flex-direction: column;
    }
  }

  .img-outer {
    will-change: transform, filter;
    transform: translateY(40%);
    z-index: 1;
    filter: grayscale(1);
  }

  .img-wrap {
    will-change: transform;
    width: 37vh;
    height: 55vh;
    position: relative;
    overflow: hidden;
    max-width: 400px;
    max-height: 540px;
    transform-origin: bottom center;
    transform: translateY(100%);
  }

  .gatsby-image-wrapper {
    will-change: transform;
    position: absolute;
    height: 100%;
    width: 100%;
    transform-origin: bottom;
    transform: translateY(-100%);
  }

  .swipe {
    will-change: transform;
    width: 100%;
    height: 100%;
    transform: scaleY(0);
    background: #000;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 0;
    transform-origin: bottom;
  }

  .preWrap {
    will-change: transform;
    z-index: 5;
    max-width: 800px;
    mix-blend-mode: difference;
  }

  svg {
    width: 100%;
  }

  ${device.laptop} {
    .img-wrap {
      /* width: 35vw;
      height: 70vh; */
    }
  }
`;
