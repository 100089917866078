import React, { useEffect, useRef, useContext } from "react";
import styled from "styled-components";
import gsap from "gsap";
import { GlobalStateContext } from "../../context/Global";
import { StaticImage } from "gatsby-plugin-image";
import { circ, quint } from "../../vars/ease";
import Wrapper from "../Wrapper";
import Logo from "../../images/svg/bertchcapital-logo.svg";
import Mark from "../../images/mark.svg";
import { colours } from "../../vars/colours";
import { device } from "../../vars/media";
import ScrollTrigger from "gsap/ScrollTrigger";
import ScrollSmoother from "gsap/ScrollSmoother";

const Wipe = () => {
  gsap.registerPlugin(ScrollTrigger, ScrollSmoother);
  const { preloaded, setPreloaded, setTransitioned } =
    useContext(GlobalStateContext);

  useEffect(() => {
    if (!preloaded) {
      const wipeFirst = document.querySelector(".wipe-first");
      const wipeSecond = document.querySelector(".wipe.accent");
      const wipeGroup = document.querySelector(".wipe-group");

      gsap.set("body", { opacity: 1 });
      gsap.set("main", { opacity: 0 });
      // const currentScroll = ScrollSmoother.get();
      // currentScroll.paused(true);

      function isTouchDevice() {
        return (
          "ontouchstart" in window ||
          navigator.maxTouchPoints > 0 ||
          navigator.msMaxTouchPoints > 0
        );
      }

      setTransitioned(true);

      const inTl = gsap.timeline({
        onStart: () => {
          setTimeout(() => {
            gsap.set("main", { opacity: 1 });
          }, 600);
        },
        onComplete: () => {
          inTl.revert();
          setPreloaded(true);
          // currentScroll.paused(false);

          // gsap.set(wipeFirst.querySelector(".wipe__inner"), {
          //   clearProps: "all",
          // });
          // gsap.set(wipeSecond, {
          //   clearProps: "all",
          // });
          // gsap.set(wipeSecond.querySelector(".wipe__inner"), {
          //   clearProps: "all",
          // });

          // gsap.set(wipeGroup.querySelectorAll("svg"), {
          //   clearProps: "all",
          // });

          // gsap.set(wipeFirst, {
          //   clearProps: "all",
          // });
        },
      });

      inTl
        .to(
          "main:nth-of-type(1)",
          {
            position: isTouchDevice() ? "fixed" : "absolute",
            width: "100%",
            // display: "none",
            height: 0,
            duration: 0.01,
            ease: "none",
          },
          0
        )
        .to(
          wipeFirst,
          {
            y: 0,
            ease: circ,
            duration: 0.4,
          },
          0
        )
        .to(
          wipeFirst.querySelector(".wipe__inner"),
          {
            y: 0,
            ease: circ,
            duration: 0.4,
          },
          0
        )
        .to(
          wipeGroup.querySelectorAll("svg"),
          {
            scale: 1.05,
            ease: circ,
            duration: 0.3,
          },
          0.1
        )

        .to(
          wipeGroup.querySelectorAll("svg"),
          {
            scale: 1.1,
            ease: circ,
            duration: 0.3,
          },
          0.4
        )

        .to(
          wipeSecond,
          {
            y: 0,
            ease: circ,
            duration: 0.4,
          },
          0.4
        )

        .to(
          wipeSecond.querySelector(".wipe__inner"),
          {
            y: 0,
            ease: circ,
            duration: 0.4,
          },
          0.4
        )

        .to(
          wipeFirst,
          {
            autoAlpha: 0,
          },
          1
        )

        .to(
          wipeSecond,
          {
            yPercent: -100,
            ease: circ,
            duration: 0.55,
          },
          1
        )
        .to(
          wipeSecond.querySelector(".wipe__inner"),
          {
            yPercent: 100,
            ease: circ,
            duration: 0.55,
          },
          1
        );
      inTl.play(0);
    }
  }, []);
};

export default Wipe;

const Preload = styled.div`
  pointer-events: none;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  overflow: visible;
  pointer-events: none;
  z-index: 10;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  will-change: transform;

  .logos {
    opacity: 0;
    position: absolute;
    z-index: 0;
    left: 0;
    top: 0;
    width: 100%;
    height: 100vh;
    z-index: 2;

    svg path {
      fill: ${colours.accent};
    }

    svg:nth-of-type(2) {
      width: 40px;
    }

    .wrapper {
      margin: 1.875rem;
      height: 100%;
      justify-content: space-between;
      align-items: center;
      display: flex;
      flex-direction: column;
    }
  }

  .img-outer {
    transform: translateY(40%);
    z-index: 1;
    filter: grayscale(1);
  }

  .img-wrap {
    will-change: transform;
    width: 37vh;
    height: 55vh;
    position: relative;
    overflow: hidden;
    max-width: 400px;
    max-height: 540px;
    transform-origin: bottom center;
    transform: translateY(100%);
  }

  .gatsby-image-wrapper {
    position: absolute;
    height: 100%;
    width: 100%;
    transform-origin: bottom;
    transform: translateY(-100%);
  }

  .swipe {
    width: 100%;
    height: 100%;
    transform: scaleY(0);
    background: #000;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 0;
    transform-origin: bottom;
  }

  .preWrap {
    z-index: 5;
    max-width: 800px;
    mix-blend-mode: difference;
  }

  svg {
    width: 100%;
  }

  ${device.laptop} {
    .img-wrap {
      /* width: 35vw;
      height: 70vh; */
    }
  }
`;
