import React from "react";

import Page from "./src/components/Page";
import Preloader from "./src/components/global/Preloader";
import Wipe from "./src/components/global/Wipe";
import PageTransition from "./src/components/PageTransition";

import "./src/fonts/ABCMonumentGrotesk-Regular.woff";
import "./src/fonts/ABCMonumentGrotesk-Regular.woff2";
import "./src/fonts/ABCMonumentGrotesk-Medium.woff";
import "./src/fonts/ABCMonumentGrotesk-Medium.woff2";

import { GlobalStateProvider } from "./src/context/Global";

// export const shouldUpdateScroll = () => {
//   // Disable all active ScrollTriggers when the route changes
//   const allTriggers = ScrollTrigger.getAll();
//   allTriggers.forEach((trigger) => {
//     trigger.disable();
//   });
//   return false;
// };

// export const onRouteUpdate = () => {
//   // Re-enable all ScrollTriggers once the new page has loaded
//   const allTriggers = ScrollTrigger.getAll();
//   allTriggers.forEach((trigger) => {
//     trigger.enable();
//   });
// };

export const shouldUpdateScroll = () => false;

export const wrapPageElement = ({ element, props }) => {
  const path = props.location.pathname;
  return (
    <GlobalStateProvider>
      {path == "/" ? <Preloader /> : <Wipe />}
      <PageTransition location={props.location}>
        <Page {...props}>{element}</Page>
      </PageTransition>
      <div className="ol"></div>
    </GlobalStateProvider>
  );
};
