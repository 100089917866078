import React, {
  useRef,
  useLayoutEffect,
  useEffect,
  useContext,
  useState,
} from "react";
import Header from "./partials/Header";
import Footer from "./partials/Footer";
import Nav from "./partials/Nav";
import NavDesktop from "./partials/NavDesktop";
import "../style.css";
import Progress from "./partials/Progress";
import gsap from "gsap";
import Contact from "./global/Contact";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ScrollSmoother } from "gsap/ScrollSmoother";
import { quint } from "../vars/ease";
import { GlobalStateContext } from "../context/Global";

import Cursor from "./partials/Cursor";

const Page = ({ children }) => {
  gsap.registerPlugin(ScrollTrigger, ScrollSmoother);
  const {
    cursorIn,
    setCursorIn,
    preloaded,
    transitioned,
    inPortfolio,
    scrollP,
    setScrollP,
  } = useContext(GlobalStateContext);

  const [norm, setNorm] = useState(false);
  const wrapper = useRef();
  const content = useRef();

  gsap.config({
    nullTargetWarn: false,
    trialWarn: false,
    force3D: true,
  });

  ScrollTrigger.config({
    // default is "resize,visibilitychange,DOMContentLoaded,load" so we can remove "resize" from the list:
    ignoreMobileResize: true,
  });

  useEffect(() => {
    if ("scrollRestoration" in window.history) {
      window.history.scrollRestoration = "manual";
    }

    setCursorIn(false);

    //   gsap.matchMedia().add("(min-width: 1023px)", () => {
    //     setNorm(true);
    //     const currentSmooth = ScrollSmoother.get();
    //     currentSmooth.kill();

    //     const smoother = ScrollSmoother.create({
    //       wrapper: wrapper.current,
    //       content: content.current,
    //       smooth: 3.2,
    //       smoothTouch: false,
    //       normalizeScroll: true,
    //       ignoreMobileResize: true,
    //     });
    //     return () => {
    //       setNorm(false);
    //     };
    //   });
  }, []);

  useLayoutEffect(() => {
    function isTouchDevice() {
      return (
        "ontouchstart" in window ||
        navigator.maxTouchPoints > 0 ||
        navigator.msMaxTouchPoints > 0
      );
    }

    if (!isTouchDevice()) {
      gsap.set(".content", {
        willChange: "transform",
      });

      const currentSmooth = ScrollSmoother.get();

      if (!currentSmooth) {
        const smoother = ScrollSmoother.create({
          wrapper: wrapper.current,
          content: content.current,
          smooth: 3.2,
          smoothTouch: false,
          normalizeScroll: false,
          ignoreMobileResize: true,
        });

        // smoother.paused(true);
      } else {
        setTimeout(() => {
          if (!inPortfolio) {
            const smoother = ScrollSmoother.create({
              wrapper: wrapper.current,
              content: content.current,
              smooth: 3.2,
              smoothTouch: false,
              normalizeScroll: false,
              ignoreMobileResize: true,
            });

            window.scrollTo(0, 0);
            smoother.scrollTo(0);
          }
        }, 1100); //Fix flash

        setTimeout(() => {
          setCursorIn(false);
          if (inPortfolio) {
            const smoother = ScrollSmoother.create({
              wrapper: wrapper.current,
              content: content.current,
              smooth: 3.2,
              smoothTouch: false,
              normalizeScroll: false,
              ignoreMobileResize: true,
            });

            setTimeout(() => {
              // smoother.paused(false);
              smoother.scrollTop(scrollP);
            }, 500);
          }
        }, 700);
      }
    }
  }, []);

  return (
    <main>
      <Nav />
      <NavDesktop />
      {transitioned && <Cursor />}
      <div ref={wrapper} className="inset">
        <div ref={content} className="content">
          {transitioned && (
            <div id="inner">
              <Header
                theme={
                  children.key.includes("portfolio") &&
                  children.key !== "/portfolio/"
                    ? "dark"
                    : "default"
                }
              />
              {children}
              <Footer />
            </div>
          )}
        </div>
      </div>
      {transitioned && <Progress />}
      <Contact />
    </main>
  );
};

export default Page;
