import { graphql, useStaticQuery } from "gatsby";
import React, { useEffect, useRef, useState, useLayoutEffect } from "react";
import styled from "styled-components";
import { colours } from "../../vars/colours";
import { FiCopy } from "react-icons/fi";
import { FiCheck } from "react-icons/fi";
import gsap from "gsap";
import Icon from "../partials/Icon";
import { GrClose } from "react-icons/gr";
import Title from "../Title";
import { device } from "../../vars/media";
import { quint } from "../../vars/ease";

const Contact = () => {
  const data = useStaticQuery(query);
  const ref = useRef(null);
  const contact = data.allSanityContact.edges[0].node;
  const [copyText, setCopyText] = useState("Click to copy");
  const [copyIcon, setCopyIcon] = useState(<FiCopy />);
  const copyRef = useRef(null);
  const tl = useRef();
  const btns = useRef(null);
  const [openDrawer, setOpenDrawer] = useState(false);

  const copy = () => {
    navigator.clipboard.writeText(contact.email);
    setCopyText("Copied to clipboard!");
    setCopyIcon(<FiCheck />);

    setInterval(() => setCopyText("Click to copy"), 3000);
    setInterval(() => setCopyIcon(<FiCopy />), 3000);
  };

  useEffect(() => {
    tl.current = gsap.timeline({ paused: true });
    const inner = ref.current.querySelector(".drawer__inner");

    tl.current
      .to(
        ".ol",
        {
          autoAlpha: 1,
          duration: 0.7,
          ease: quint,
        },
        0
      )
      .to(
        ref.current,
        {
          x: 0,
          y: 0,
          yPercent: 0,
          z: 0.01,
          autoAlpha: 1,
          duration: 0.7,
          ease: quint,
        },
        0
      )
      .to(
        inner,
        {
          x: 0,
          y: 0,
          autoAlpha: 1,
          duration: 0.7,
          ease: quint,
        },
        0
      );

    document
      .querySelector(".nav__item-contact")
      .addEventListener("click", () => {
        setOpenDrawer(true);
      });

    document
      .querySelector(".nav__item-contact-m")
      .addEventListener("click", () => {
        setOpenDrawer(true);
      });

    ref.current.querySelector(".icon").addEventListener("click", () => {
      setOpenDrawer(false);
    });

    document.querySelector(".ol").addEventListener("click", () => {
      setOpenDrawer(false);
    });
  }, []);

  useEffect(() => {
    btns.current = document.querySelectorAll(".button--contact");

    btns.current.forEach((btn, i) => {
      btn.addEventListener("click", () => {
        setOpenDrawer(true);
      });
    });
  });

  useEffect(() => {
    openDrawer
      ? tl.current.timeScale(1).play()
      : tl.current.timeScale(1.5).reverse();
  }, [openDrawer]);

  return (
    <Drawer className="drawer" ref={ref}>
      <div className="drawer__inner">
        <div className="drawer__top">
          <Title h="h5">{contact.title}</Title>
          <Icon>
            <GrClose />
          </Icon>
        </div>

        {contact.address ? (
          <div className="drawer__details">
            <div className="caps">Address</div>
            <a
              href={`http://maps.google.com/?q=${contact.address}`}
              target="_blank"
            >
              {contact.address}
            </a>
          </div>
        ) : null}

        {contact.telephone ? (
          <div className="drawer__details">
            <div className="caps">Telephone</div>
            <a href={`tel:${contact.telephone}`}>{contact.telephone}</a>
          </div>
        ) : null}

        {contact.address ? (
          <div className="drawer__details">
            <div className="caps">Email</div>
            <div className="c2c" ref={copyRef} onClick={() => copy()}>
              <p>{copyText}</p>
              {copyIcon}
            </div>
          </div>
        ) : null}
      </div>
    </Drawer>
  );
};

export default Contact;

export const query = graphql`
  query Contact {
    allSanityContact {
      edges {
        node {
          title
          address
          telephone
          email
        }
      }
    }
  }
`;

const Drawer = styled.div`
  will-change: transform;
  position: fixed;
  background-color: ${colours.accent};
  top: 0;
  right: 0;
  z-index: 10;
  flex-direction: column;
  width: calc(100% - 1.25rem);
  padding: 2.5rem 2.1875rem;
  border-bottom-left-radius: 1.25rem;
  white-space: pre-line;
  overflow: hidden;
  transform: translateX(50%) translateY(-50%);
  visibility: hidden;
  opacity: 0;

  .icon {
    cursor: pointer;
  }

  .drawer__inner {
    transform: translateX(-25%) translateY(25%);
    opacity: 0;
    visibility: hidden;
  }

  p {
    margin: 0;
  }

  .drawer__details {
    margin-top: 1.875rem;
  }

  .c2c {
    cursor: pointer;
    display: flex;
    align-items: center;

    svg {
      margin-left: 10px;
    }
  }

  a {
    all: unset;
    text-decoration: none;
    cursor: pointer;
  }

  h5 {
    font-size: 3rem;
    font-weight: 300;
    margin: 0;
  }

  .icon {
    margin-right: 0;
    border-color: ${colours.black};
  }

  .drawer__top {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    margin-bottom: 7.5rem;
  }

  ${device.tablet} {
    width: auto;
    min-width: 395px;

    h5 {
      margin-right: 5.3125rem;
    }
  }

  ${device.desktop} {
    p,
    a {
      font-size: 1.15rem;
    }
  }

  /* ${device.desktopM} {
    a {
      font-size: 1.4125rem;
    }
  } */
`;
