import React from "react";
import styled from "styled-components";
import { colours } from "../../vars/colours";
import Wrapper from "../Wrapper";
import { Link } from "gatsby";
import Logo from "../../images/svg/bertchcapital-logo.svg";
import { device } from "../../vars/media";
import Marquee from "react-fast-marquee";

const Footer = () => {
  return (
    <StyledFooter>
      <div className="logo-wrap">
        <Marquee speed={100} gradient={false}>
          <Logo />
        </Marquee>
      </div>
      <Wrapper>
        <div className="footer__links">
          <div>
            <Link to="/privacy">Privacy</Link>
            <Link to="/terms">Terms</Link>
          </div>

          <div>
            <span>©{new Date().getFullYear()} Bertch Capital</span>
            <span className="made-by">
              Made by{" "}
              <a href="https://likely-story.co.uk/" target="_blank">
                Likely Story
              </a>
            </span>
          </div>
        </div>
      </Wrapper>
    </StyledFooter>
  );
};

export default Footer;

const StyledFooter = styled.footer`
  overflow: hidden;
  /* margin-top: 6.25rem; */
  padding: 5rem 0 7.5rem 0;
  background-color: ${colours.accent};
  text-align: center;

  .logo-wrap {
    will-change: transform;
    margin-bottom: 90px;
    width: 100vw;

    .marquee {
      will-change: transform;
    }
  }

  a {
    text-decoration: none;
    color: #000;
  }

  .made-by {
    display: flex;
    align-items: center;
    justify-content: center;

    a {
      display: block;
      text-decoration: none;
      color: #000;
      padding-left: 4px;
      margin-top: 0;
    }
  }

  svg {
    width: 100vw;
    height: auto;
    margin: 0 0.9rem;

    ${device.laptop} {
      margin: 0 1.5rem;
    }

    ${device.laptopL} {
      margin: 0 2rem;
    }

    ${device.desktop} {
      margin: 0 3.5rem;
    }

    path {
      fill: ${colours.black};
    }
  }

  a,
  span {
    display: block;
    margin-top: 1.25rem;
  }

  ${device.tablet} {
    padding-bottom: 3.125rem;

    .footer__links {
      display: flex;
      justify-content: space-between;
      flex-direction: row-reverse;

      > div {
        display: flex;
        align-items: center;
      }

      a,
      span {
        margin: 0;
        margin-left: 1.875rem;

        &:nth-of-type(1) {
          margin-left: 0;
        }
      }
    }
  }

  ${device.desktopM} {
    span,
    a {
      font-size: 1.15rem;
    }
  }
`;
