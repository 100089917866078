import React, { useEffect, useRef, useContext, useState } from "react";
import styled from "styled-components";
import gsap from "gsap";
import { graphql, Link, useStaticQuery } from "gatsby";
import { BsArrowRight } from "react-icons/bs";
import { IoMdClose } from "react-icons/io";
import { device } from "../../vars/media";
import { quint } from "../../vars/ease";
import ScrollTrigger from "gsap/ScrollTrigger";
import { GlobalStateContext } from "../../context/Global";
import { colours } from "../../vars/colours";

const NavDesktop = (props) => {
  const pageTitle = useRef(document.location.pathname);
  const { portfolioEnter } = useContext(GlobalStateContext);
  gsap.registerPlugin(ScrollTrigger);
  const data = useStaticQuery(query);
  const links = data.allSanityNavigation.edges[0].node.items;
  const [inPort, setInPort] = useState(
    pageTitle.current.includes("portfolio") &&
      pageTitle.current !== "/portfolio/"
  );

  const [toggleNav, setToggleNav] = useState(false);
  const ref = useRef(null);
  const bottomNav = useRef(null);
  const tl = useRef(null);
  const timeout = useRef(null);
  const cleanTitle = useRef(null);

  useEffect(() => {
    if (pageTitle.current === "/") {
      pageTitle.current = "Home";
    } else if (inPort) {
      pageTitle.current = "Close";
    } else {
      pageTitle.current = document.location.pathname;
    }
    cleanTitle.current = pageTitle.current.replace(/[^a-zA-Z ]/g, "");
  }, [inPort]);

  useEffect(() => {
    !inPort ? setToggleNav(false) : setToggleNav(true);

    setTimeout(() => {
      ScrollTrigger.create({
        start: "2%",
        onEnter: () => {
          setToggleNav(true);
        },
      });
    }, 300);

    tl.current = gsap.timeline({ paused: true });
    const navIcon = bottomNav.current.querySelector("svg");
    const wrap = ref.current.querySelector(".nav__wrap");
    const items = wrap.querySelectorAll("a");
    const current = ref.current.querySelector(".nav__item-current");

    tl.current
      .to(navIcon, { fill: colours.black }, 0.1)
      .from(
        current,
        {
          autoAlpha: 0,
          width: 0,
          ease: quint,
          duration: 0.5,
        },
        0
      )
      .to(
        wrap,
        {
          width: 0,
          ease: quint,
          duration: 0.5,
        },
        0
      )
      .to(
        items,
        {
          autoAlpha: 0,
          ease: quint,
          duration: 0.5,
        },
        0
      );
  }, []);

  useEffect(() => {
    const mouseEnter = () => {
      clearTimeout(timeout.current);
      toggleNav ? setToggleNav(!toggleNav) : setToggleNav(toggleNav);
    };

    const mouseLeave = () => {
      timeout.current = setTimeout(() => {
        setToggleNav(!toggleNav);
      }, 500);
    };
    if (!inPort) {
      bottomNav.current.addEventListener("mouseenter", mouseEnter);
      bottomNav.current.addEventListener("mouseleave", mouseLeave);
    }
  }, []);

  useEffect(() => {
    toggleNav ? tl.current.play() : tl.current.reverse();
  }, [toggleNav]);

  const navItems = links.map((link, index) => {
    const item = link.navigationItemUrl;
    let url = "";
    const title = link.text;

    if (item.internalLink && item.internalLink.slug_regular_custom_input) {
      url = link.navigationItemUrl.internalLink.slug_regular_custom_input;
    }

    return item.externalUrl !== "#contact" ? (
      <Link
        to={`/${url.current == "home" ? "" : url.current}`}
        className="nav__item"
        activeClassName="nav__item-active"
      >
        <div className="dot">
          <div className="dot__inner"></div>
        </div>
        {title}
      </Link>
    ) : (
      <span className="nav__item nav__item-contact">{title}</span>
    );
  });

  const navInner = (
    <StyledNav className="nav" open={props.open} ref={ref} inPort={inPort}>
      <div className="nav__bottom" ref={bottomNav}>
        <div className="nav__icon">
          {!inPort ? <BsArrowRight /> : <IoMdClose />}
        </div>

        {!inPort ? (
          <span className="nav__item nav__item-current">
            {cleanTitle.current}
          </span>
        ) : (
          <span className="nav__item nav__item-current pexit">
            {cleanTitle.current}
          </span>
        )}
        <div className="nav__wrap">{navItems}</div>
      </div>
    </StyledNav>
  );

  const navInnerP = (
    <StyledNav className="nav" open={props.open} ref={ref} inPort={inPort}>
      <Link to={portfolioEnter}>
        <div className="nav__bottom" ref={bottomNav}>
          <div className="nav__icon">
            {!inPort ? <BsArrowRight /> : <IoMdClose />}
          </div>

          {!inPort ? (
            <span className="nav__item nav__item-current">
              {cleanTitle.current}
            </span>
          ) : (
            <span className="nav__item nav__item-current pexit">
              {cleanTitle.current}
            </span>
          )}
          <div className="nav__wrap">{navItems}</div>
        </div>
      </Link>
    </StyledNav>
  );

  return <>{!inPort ? navInner : navInnerP}</>;
};

export default NavDesktop;

const StyledNav = styled.nav`
  display: none;
  position: fixed;
  left: 50%;
  transform: translate(-50%, 0%);
  bottom: 1.875rem;
  z-index: 5;
  font-weight: 500;
  cursor: pointer;
  opacity: 0;
  text-decoration: none;
  color: ${colours.black};

  &:hover {
    .pexit {
      color: ${colours.accent};
    }
  }

  ${device.laptop} {
    display: block;
  }

  a,
  span {
    all: unset;
    margin: 0 0.937rem;
  }

  .nav__item {
    transition: color 0.15s ease-in-out;
    &:hover {
      color: ${colours.accent};
    }
  }

  .nav__item-current {
    margin: 0;
    text-transform: capitalize;
    width: ${(props) => (props.inPort ? "auto !important" : "unset")};

    a {
      display: block;
      margin: 0;
    }
  }

  .nav__main {
    background-color: white;
    /* padding: 1.5625rem; */
    border-radius: 30px;
    transform-origin: bottom;
    max-height: 0px;
    overflow: hidden;
  }

  .nav__bottom {
    background-color: white;
    display: inline-flex;
    align-items: center;
    padding: 10px;
    border-radius: 4.375rem;
    padding-right: 2.5rem;
    margin-top: 1rem;
    white-space: nowrap;
    overflow: hidden;
    -webkit-box-shadow: 0px 4px 30px -10px rgba(0, 0, 0, 0.25);
    box-shadow: 0px 4px 30px -10px rgba(0, 0, 0, 0.25);
  }

  .nav__wrap {
    display: inline-flex;

    .nav__item {
      &:first-child {
        margin-left: 0;
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .nav__icon {
    height: 2.5rem;
    width: 2.5rem;
    border-radius: 2.5rem;
    border: 1px solid #d9d9d9;
    margin-right: 1.25rem;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      transform-origin: center;
      fill: ${colours.accent};
    }
  }
`;

export const query = graphql`
  query DesktopNav {
    allSanityNavigation(filter: { title: { eq: "Main Menu" } }) {
      edges {
        node {
          id
          items {
            text
            navigationItemUrl {
              internalLink {
                slug_regular_custom_input {
                  current
                }
              }
              externalUrl
            }
          }
        }
      }
    }
  }
`;
