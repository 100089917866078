import React, { useState, useContext, useEffect, useRef } from "react";
import styled from "styled-components";
import { colours } from "../../vars/colours";
import { BsArrowRight } from "react-icons/bs";
import { BsArrowLeft } from "react-icons/bs";
import { AiOutlinePause } from "react-icons/ai";
import { BiPlay } from "react-icons/bi";
import gsap from "gsap";
import { GlobalStateContext } from "../../context/Global";
import { quint } from "../../vars/ease";

const Cursor = () => {
  const [touch, setTouch] = useState(null);
  const [cursorType, setCursorType] = useState("carousel");
  const [play, setPlay] = useState(true);
  const { cursorIn, setCursorIn, transitioned } =
    useContext(GlobalStateContext);
  const ref = useRef(null);
  const tl = useRef(null);

  gsap.set(ref.current, {
    xPercent: -50,
    yPercent: -50,
  });

  useEffect(() => {
    setCursorIn(false);
    function isTouchDevice() {
      return (
        "ontouchstart" in window ||
        navigator.maxTouchPoints > 0 ||
        navigator.msMaxTouchPoints > 0
      );
    }

    setTouch(isTouchDevice);

    const curs = document.querySelectorAll(".curs");
    const ball = ref.current;
    const pos = { x: window.innerWidth / 2, y: window.innerHeight / 2 };
    const mouse = { x: pos.x, y: pos.y };
    let rotate = 0;

    gsap.set(ball, {
      x: pos.x,
      y: pos.y,
    });

    curs.forEach((c, i) => {
      c.addEventListener("mouseenter", (e) => {
        c.classList.contains("vid-wrap")
          ? setCursorType("player")
          : setCursorType("cursor");
      });

      c.addEventListener("mouseleave", (e) => {
        setCursorIn(false);
      });

      c.addEventListener("mousemove", (e) => {
        const docPos = Math.round(
          parseFloat(e.clientX / window.innerWidth) * 100
        );
        rotate = 50 - docPos;
        mouse.x = e.x;
        mouse.y = e.y;

        gsap.to(ball, {
          x: mouse.x,
          y: mouse.y,
          rotate: -rotate,
          ease: "expo.out",
          duration: 1.33,
        });
      });

      c.addEventListener("click", (e) => {
        const clickTl = gsap.timeline({
          paused: true,
        });
        clickTl
          .to(ref.current, {
            scale: 0.9,
            duration: 0.1,
          })
          .to(ref.current, {
            scale: 1,
            duration: 0.3,
          });

        clickTl.play();
      });
    });
  }, []);

  useEffect(() => {
    if (cursorIn) {
      gsap.killTweensOf(ref.current);
      gsap.to(ref.current, {
        scale: 1,
        duration: 0.6,
        ease: quint,
      });
    } else {
      gsap.killTweensOf(ref.current);

      gsap.to(ref.current, {
        scale: 0,
        duration: 0.2,
        ease: quint,
      });
    }
    return () => gsap.killTweensOf(ref.current);
  }, [cursorIn]);

  useEffect(() => {
    setCursorIn(false);
  }, [transitioned]);

  return !touch ? (
    <StyledCursor
      className="cc"
      ref={ref}
      cursorType={cursorType}
      playing={play}
    >
      <div className="arrow-wrap">
        <BsArrowLeft />
        <span className="counter"></span>
        <BsArrowRight />
      </div>

      <div className="player-wrap">
        <BiPlay />
        <AiOutlinePause />
      </div>
    </StyledCursor>
  ) : null;
};

export default Cursor;

const StyledCursor = styled.div`
  pointer-events: none;
  height: 146px;
  width: 146px;
  border-radius: 200px;
  position: fixed;
  transform: scale(0);
  top: 0;
  left: 0;
  z-index: 3;
  background-color: ${colours.accent};
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${colours.white};
  will-change: transform;

  .arrow-wrap {
    padding: 1.25rem;
    position: absolute;
    width: 100%;
    display: ${(props) => (props.cursorType == "cursor" ? "flex" : "none")};
    align-items: center;
    justify-content: space-between;
  }

  .player-wrap {
    position: absolute;
    display: ${(props) => (props.cursorType == "player" ? "flex" : "none")};
    justify-content: center;
    align-items: center;
    width: 100%;

    svg {
      position: absolute;
      height: 2.2rem;
      width: auto;

      &:nth-of-type(1) {
        opacity: ${(props) => (!props.playing ? 1 : 0)};
      }

      &:nth-of-type(2) {
        opacity: ${(props) => (props.playing ? 1 : 0)};
      }
    }
  }

  &.playing {
    .player-wrap {
      svg {
        &:nth-of-type(1) {
          opacity: 0;
        }

        &:nth-of-type(1) {
          opacity: 1;
        }
      }
    }
  }

  .counter__inner {
    font-weight: 300;
    span {
      display: inline-block;
      margin: 0 0.1rem;
    }
  }
`;
